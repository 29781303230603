
























import { Component, Prop, Vue } from 'vue-property-decorator'
import BotaoDeDrawer from '@/components/ui/BotaoDeDrawer.vue'
import { Deposito } from '@/models'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import IconesDaToolbarPadroes from '@/components/layout/IconesDaToolbarPadroes.vue'

@Component({
	components: {
		BotaoDeDrawer,
		IconesDaToolbarPadroes,
		SeletorDeLojasDoUsuario,
	},
})
export default class ToolbarDeComandas extends Vue {
	@Prop({ type: String, default: '' }) titulo!: string
	depositos: Deposito[] = []
	carregando = true

	get loja() {
		return this.$route.query.l as string || null
	}

	set loja(idLoja: string | null) {
		this.$router
			.push({
				name: this.$route.name as string,
				query: {
					l: idLoja,
				},
			})
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			.catch(() => {})
	}
}
